import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/menus/";

const createMenu = async (data) => (await axios.post(base, data)).data;
const getAllMenus = getAll(base);
const getMenuById = async (menuId) => (await axios.get(base + menuId)).data;
const archiveMenu = async ({ menuId }) =>
  (await axios.patch(base + menuId + "/archive")).data;
const unarchiveMenu = async ({ menuId }) =>
  (await axios.patch(base + menuId + "/unarchive")).data;
const getAllArchivedMenus = getAll(base + "archived");

const menus = {
  createMenu,
  getAllMenus,
  getMenuById,
  archiveMenu,
  unarchiveMenu,
  getAllArchivedMenus,
};

export default menus;
