import { useEffect, useState } from "react";
import { Container } from "@mui/material";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import MenuCard from "./components/MenuCard";

const ArchivedMenusPage = () => {
  const [menus, setMenus] = useState([]);

  const fetchAllArchivedMenus = async () => {
    try {
      const {
        data: { menus },
      } = await api.menus.getAllArchivedMenus();

      setMenus(menus);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchAllArchivedMenus();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Archived Menus</Title>
        {menus.map((menu) => (
          <MenuCard
            key={menu._id}
            menu={menu}
            fetchMenus={fetchAllArchivedMenus}
          />
        ))}
        {menus.length === 0 && (
          <NoListFoundText>No archived menus found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default ArchivedMenusPage;
