import { Card, CardContent } from "@mui/material";

import LabelValueText from "@/components/LabelValueText";
import { useData } from "@/contexts/DataContext";
import { verticalComponentMargin } from "@/utils/styleConstants";

import CardHeader from "./CardHeader";

const ConfigurationCard = ({ isEditable, configuration }) => {
  const data = useData();

  if (!configuration) return null;

  let {
    cuisines,
    mealTypes,
    mealTimes,
    periods,
    servingCapacityPerMealTime,
    deliveryDays,
    deliveryRadius,
  } = configuration;

  cuisines = cuisines.map((cuisine) => data.cuisineLabels[cuisine]);
  mealTypes = mealTypes.map((mealType) => data.mealTypeLabels[mealType]);
  mealTimes = mealTimes.map((mealTime) => data.mealTimeLabels[mealTime]);
  periods = periods.map((period) => data.periodLabels[period]);
  deliveryDays = deliveryDays.map((deliveryDay) => data.dayLabels[deliveryDay]);

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <CardHeader
          title={"Configuration"}
          editPath={isEditable && "configuration"}
        />
        <LabelValueText label="Cuisines" value={cuisines.join(", ")} />
        <LabelValueText label="Meal Types" value={mealTypes.join(", ")} />
        <LabelValueText label="Meal Times" value={mealTimes.join(", ")} />
        <LabelValueText label="Periods" value={periods.join(", ")} />
        <LabelValueText
          label="Serving Capacity Per Meal Time"
          value={servingCapacityPerMealTime}
        />
        <LabelValueText label="Delivery Days" value={deliveryDays.join(", ")} />
        <LabelValueText
          label="Delivery Radius"
          value={`${deliveryRadius / 1000} Km`}
        />
      </CardContent>
    </Card>
  );
};

export default ConfigurationCard;
