import { useEffect } from "react";
import { Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import BottomButton from "@/components/BottomButton";
import TextField from "@/components/FormikComponents/TextField";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

const initialValues = {
  accountNumber: "",
  accountNumberConfirm: "",
  ifscCode: "",
};

const validationSchema = Yup.object({
  accountNumber: Yup.string()
    .required("Account number is required")
    .matches(/^\d+$/, "Account number must contain only digits")
    .max(20, "Account number length shouldn't exceed 20"),
  accountNumberConfirm: Yup.string()
    .required("Re-enter account number for confirmation")
    .oneOf([Yup.ref("accountNumber"), null], "Account numbers must match"),
  ifscCode: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code (eg: HDFC0009580)")
    .required("IFSC code is required"),
});

const BankDetailsPage = () => {
  const navigate = useNavigate();

  const submitHandler = async (values) => {
    try {
      await api.kitchenRegistrations.updateBankDetails(values);
      navigate(`/kitchen-registration/legal-details`);
    } catch (error) {
      handleError(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const {
          data: { kitchenRegistration },
        } = await api.kitchenRegistrations.getMyKitchenRegistration();
        const { bankDetails } = kitchenRegistration;

        if (bankDetails) {
          formik.setValues({
            accountNumber: bankDetails.accountNumber,
            accountNumberConfirm: bankDetails.accountNumber,
            ifscCode: bankDetails.ifscCode,
          });
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchBankDetails();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Bank details</Title>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label={"Bank account number*"}
            name={"accountNumber"}
            formik={formik}
            number
            type={"password"}
          />
          <TextField
            label={"Re-enter bank account number*"}
            name={"accountNumberConfirm"}
            formik={formik}
            number
          />
          <TextField label={"IFSC code*"} name={"ifscCode"} formik={formik} />
          <BottomButton>Legal details</BottomButton>
        </form>
      </Container>
    </>
  );
};

export default BankDetailsPage;
