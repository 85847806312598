import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import TextField from "@/components/FormikComponents/TextField";
import api from "@/api";
import handleError from "@/utils/handleError";
import { verticalComponentMargin } from "@/utils/styleConstants";

const validationSchema = Yup.object({
  title: Yup.string().required("Menu title is required"),
  description: Yup.string().required("Menu description is required"),
  price: Yup.number().required("Menu price is required"),
});

const MenuScheduleForm = () => {
  const navigate = useNavigate();
  const { cuisine, mealType, mealTime, day } = useParams();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const {
          data: {
            menu: { _id: menuId },
          },
        } = await api.menus.createMenu({
          cuisine,
          mealType,
          mealTime,
          ...values,
        });

        await api.menuSchedules.scheduleMenu({
          menuId,
          day,
        });

        toast.success("Menu created and scheduled successfully");
        navigate(-1);
      } catch (error) {
        handleError(error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} margin={verticalComponentMargin}>
      <TextField formik={formik} label={"Title"} name={"title"} />
      <TextField
        formik={formik}
        label={"Description"}
        name={"description"}
        multiline
        rows={3}
      />
      <TextField formik={formik} label={"Price(₹)"} name={"price"} number />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        style={{ marginTop: "10px" }}
      >
        Schedule Menu
      </Button>
    </form>
  );
};

export default MenuScheduleForm;
