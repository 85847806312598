import { useEffect, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Address from "@/components/Address/Address";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import ExistingAddress from "./components/ExistingAddress";

const AddressPage = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState(null);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const {
          data: {
            kitchenRegistration: { address },
          },
        } = await api.kitchenRegistrations.getMyKitchenRegistration();
        setAddress(address);
      } catch (error) {
        handleError(error);
      }
    };
    fetchAddress();
  }, []);

  const updateAddress = async (address) => {
    try {
      await api.kitchenRegistrations.updateAddress(address);
      navigate("/kitchen-registration/configuration");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Title
          button={
            address && (
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() => navigate("/kitchen-registration/address/edit")}
              >
                Edit Address
              </Button>
            )
          }
        >
          Address
        </Title>
        {address ? (
          <ExistingAddress address={address} />
        ) : (
          <Address submitHandler={updateAddress} />
        )}
      </Container>
    </>
  );
};

export default AddressPage;
