import { capitalize } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import { formatTimestamp } from "@/utils/dateUtils";
import { verticalComponentMargin } from "@/utils/styleConstants";

const colorMap = {
  // kitchen registration
  created: "blue",
  applied: "orange",
  approved: "green",
  declined: "red",

  // delivery
  dispatched: "orange",
  cancelled: "red",
  missed: "red",
  completed: "green",
};

const TimelineDotIcon = ({ color }) => (
  <SvgIcon>
    <circle cx="12" cy="12" r="8" fill={color} />
  </SvgIcon>
);

const Timeline = ({ timeline = [] }) => {
  if (!timeline) return null;
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <List>
          {timeline.map((item, index) => (
            <ListItem
              key={index}
              style={{ padding: 0, alignItems: "flex-start" }}
            >
              <ListItemIcon style={{ marginTop: "5px" }}>
                <TimelineDotIcon color={colorMap[item.status]} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <Typography variant="body1">
                      {capitalize(item.status)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatTimestamp(item.timestamp)}
                    </Typography>
                  </>
                }
                secondary={
                  item.notes && (
                    <Typography variant="body2">{item.notes}</Typography>
                  )
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Timeline;
