import { Card, CardContent } from "@mui/material";

import LabelValueText from "@/components/LabelValueText";
import { verticalComponentMargin } from "@/utils/styleConstants";

import CardHeader from "./common/CardHeader";

const LegalDetailsDisplay = ({ isEditable, legalDetails }) => {
  let {
    panDetails: { panNumber, panName },
    gstDetails: { isGstRegistered, gstNumber },
    fssaiDetails: { fssaiNumber },
  } = legalDetails;

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <CardHeader
          title="Legal Details"
          editPath={isEditable && "/kitchen-registration/legal-details"}
        />
        <LabelValueText label="PAN Number" value={panNumber} />
        <LabelValueText label="PAN Name" value={panName} />
        <LabelValueText
          label="GST Registered"
          value={isGstRegistered ? "yes" : "No"}
        />
        {isGstRegistered && (
          <LabelValueText label="GST Number" value={gstNumber} />
        )}
        <LabelValueText label="FSSAI Number" value={fssaiNumber} />
      </CardContent>
    </Card>
  );
};

export default LegalDetailsDisplay;
