import api from "@/api";
import handleError from "@/utils/handleError";

import extractAddressComponents from "./extractAddressComponents";

const getLocationDetailsByGooglePlace = async (placeId) => {
  try {
    const {
      data: {
        addressComponents,
        formattedAddress,
        location: { latitude, longitude },
      },
    } = await api.maps.getPlaceDetails({ placeId });

    const { area, locality, state, pincode, country } =
      extractAddressComponents(addressComponents, "longText");
    const googleMapsLocationData = {
      formattedAddress,
      addressComponents,
      geometry: { location: { lat: latitude, lng: longitude } },
      placeId,
    };

    return {
      area,
      locality,
      state,
      pincode,
      country,
      latitude,
      longitude,
      googleMapsLocationData,
    };
  } catch (error) {
    handleError(error);
    return null;
  }
};

export default getLocationDetailsByGooglePlace;
