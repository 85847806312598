import AddMenuPage from "./pages/AddMenuPage";
import ArchivedMenusPage from "./pages/ArchivedMenusPage";
import MenusPage from "./pages/MenusPage";

const menuRoutes = [
  {
    path: "menus",
    component: <MenusPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "menus/archived",
    component: <ArchivedMenusPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "menus/add",
    component: <AddMenuPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
];

export default menuRoutes;
