import { Card, CardContent, Typography } from "@mui/material";

import TextField from "@/components/FormikComponents/TextField";
import { verticalComponentMargin } from "@/utils/styleConstants";

const PanDetails = ({ formik }) => {
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <Typography variant="subtitle1">PAN Details</Typography>
        <Typography variant="caption" color={"text.secondary"}>
          Enter the PAN details of the person or company who legally owns the
          kitchen.
        </Typography>
        <TextField
          label="PAN Number*"
          name="panNumber"
          formik={formik}
          uppercase
          style={{ marginTop: "10px" }}
        />
        <TextField label="Full Name*" name="panName" formik={formik} />
      </CardContent>
    </Card>
  );
};

export default PanDetails;
