import {
  Alert,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import TextField from "@/components/FormikComponents/TextField";
import { verticalComponentMargin } from "@/utils/styleConstants";

const FssaiDetails = ({ formik }) => {
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <Typography variant="subtitle1">FSSAI Details</Typography>
        <Typography variant="caption" color={"text.secondary"}>
          This is required to comply with regulations on food safety
        </Typography>
        <TextField
          label="FSSAI Number*"
          name="fssaiNumber"
          formik={formik}
          number
          maxLength={14}
          style={{ marginTop: "10px" }}
        />
        <Alert severity="info" style={{ margin: verticalComponentMargin }}>
          <Typography>FSSAI requirements</Typography>
          <List dense>
            <ListItem>
              <ListItemText>
                The name on the FSSAI certificate should match either the
                kitchen's name or the name on the PAN card.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                The address on the FSSAI certificate should match the kitchen's
                address.
              </ListItemText>
            </ListItem>
          </List>
        </Alert>
      </CardContent>
    </Card>
  );
};

export default FssaiDetails;
