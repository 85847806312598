import { Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import BottomButton from "@/components/BottomButton";

import Map from "../../../components/Map";

const ExistingAddress = ({ address }) => {
  const navigate = useNavigate();
  const { location, displayAddress } = address;
  const [longitude, latitude] = location.coordinates;

  return (
    <>
      <Card>
        <CardContent>
          <Typography style={{ margin: "10px 0px" }}>
            {displayAddress}
          </Typography>
          <Map latitude={latitude} longitude={longitude} />
        </CardContent>
      </Card>
      <BottomButton
        onClick={() => navigate("/kitchen-registration/configuration")}
      >
        Configuration
      </BottomButton>
    </>
  );
};

export default ExistingAddress;
