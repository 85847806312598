import { Typography } from "@mui/material";

import { useData } from "@/contexts/DataContext";

const CategoryTitle = ({
  cuisine,
  mealType,
  mealTime,
  period,
  textAlign = "left",
  marginBottom = "10px",
}) => {
  const { cuisineLabels, mealTypeLabels, mealTimeLabels, periodLabels } =
    useData();
  return (
    <Typography
      variant="subtitle1"
      textAlign={textAlign}
      style={{ marginBottom }}
    >
      {cuisineLabels[cuisine]} {mealTypeLabels[mealType]}{" "}
      {mealTimeLabels[mealTime]}
      {period && `  - ${periodLabels[period]}`}
    </Typography>
  );
};

export default CategoryTitle;
