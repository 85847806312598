import { useEffect, useState } from "react";
import { Container } from "@mui/material";

import Header from "@/components/Header";
import api from "@/api";
import handleError from "@/utils/handleError";

import ExistingKitchenRegistration from "./components/ExistingKitchenRegistration";
import NewRegistration from "./components/NewRegistration";

const KitchenRegistrationPage = () => {
  const [kitchenRegistration, setKitchenRegistration] = useState({});

  const isNewKitchenRegistration = kitchenRegistration._id ? false : true;

  const fetchKitchenRegistration = async () => {
    try {
      const {
        data: { kitchenRegistration },
      } = await api.kitchenRegistrations.getMyKitchenRegistration();
      setKitchenRegistration(kitchenRegistration);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchKitchenRegistration();
  }, []);

  return (
    <>
      <Header />
      <Container>
        {isNewKitchenRegistration ? (
          <NewRegistration />
        ) : (
          <ExistingKitchenRegistration
            kitchenRegistration={kitchenRegistration}
            fetchKitchenRegistration={fetchKitchenRegistration}
          />
        )}
      </Container>
    </>
  );
};

export default KitchenRegistrationPage;
