import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CardHeader = ({ title, editPath }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Typography variant="subtitle1" fontWeight={"bold"}>
        {title}
      </Typography>
      {editPath && (
        <Button
          color="warning"
          size="small"
          onClick={() => navigate(`/kitchen-registration/${editPath}`)}
        >
          Edit
        </Button>
      )}
    </div>
  );
};

export default CardHeader;
