import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Address from "@/components/Address/Address";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

const AddressEditPage = () => {
  const navigate = useNavigate();

  const [address, setAddress] = useState(null);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const {
          data: {
            kitchenRegistration: { address },
          },
        } = await api.kitchenRegistrations.getMyKitchenRegistration();

        if (address) {
          setAddress(address);
        } else {
          return navigate("/kitchen-registration/address", { replace: true });
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchAddress();
  }, [navigate]);

  const handleAddressEdit = async (address) => {
    try {
      await api.kitchenRegistrations.updateAddress(address);
      navigate("/kitchen-registration/configuration", { replace: true });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Title>Edit address</Title>
        {address && (
          <Address address={address} submitHandler={handleAddressEdit} />
        )}
      </Container>
    </>
  );
};

export default AddressEditPage;
