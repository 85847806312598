import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import BottomButton from "@/components/BottomButton";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";
import { FSSAI_REGEX, GSTIN_REGEX, PAN_REGEX } from "@/utils/regex";

import FssaiDetails from "./components/FssaiDetails";
import GstDetails from "./components/GstDetails";
import PanDetails from "./components/PanDetails";

const LegalDetailsPage = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      panNumber: "",
      panName: "",
      isGstRegistered: "",
      gstNumber: "",
      fssaiNumber: "",
    },
    validationSchema: Yup.object({
      panNumber: Yup.string()
        .required("PAN number is required")
        .matches(PAN_REGEX, "Invalid PAN format"),
      panName: Yup.string()
        .required("Full name is required")
        .min(3, "Name must be at least 3 characters long"),
      isGstRegistered: Yup.string().required(
        "Please specify if GST is registered",
      ),
      gstNumber: Yup.string().when("isGstRegistered", {
        is: (isGstRegistered) => isGstRegistered === "yes",
        then: () =>
          Yup.string()
            .required("GST Number is required")
            .matches(GSTIN_REGEX, "Invalid GST Number format"),
        otherwise: () => Yup.string().notRequired(),
      }),
      fssaiNumber: Yup.string()
        .required("FSSAI number is required")
        .matches(FSSAI_REGEX, "Invalid FSSAI format"),
    }),
    onSubmit: async (values) => {
      try {
        await api.kitchenRegistrations.updateLegalDetails(values);
        navigate("/kitchen-registration");
      } catch (error) {
        handleError(error);
      }
    },
  });

  useEffect(() => {
    const fetchLegalDetails = async () => {
      try {
        const {
          data: {
            kitchenRegistration: { legalDetails },
          },
        } = await api.kitchenRegistrations.getMyKitchenRegistration();

        if (!legalDetails) return;

        formik.setValues({
          panNumber: legalDetails.panDetails.panNumber,
          panName: legalDetails.panDetails.panName,
          isGstRegistered: legalDetails.gstDetails.isGstRegistered
            ? "yes"
            : "no",
          gstNumber: legalDetails.gstDetails.gstNumber,
          fssaiNumber: legalDetails.fssaiDetails.fssaiNumber,
        });
      } catch (error) {
        handleError(error);
      }
    };

    fetchLegalDetails();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Legal Details</Title>
        <form onSubmit={formik.handleSubmit}>
          <PanDetails formik={formik} />
          <GstDetails formik={formik} />
          <FssaiDetails formik={formik} />
          <BottomButton
            type="submit"
            color={formik.isValid && formik.dirty ? "success" : "primary"}
          >
            Continue
          </BottomButton>
        </form>
      </Container>
    </>
  );
};

export default LegalDetailsPage;
