import api from "@/api";
import handleError from "@/utils/handleError";

import extractAddressComponents from "./extractAddressComponents";

const getLocationDetailsByReverseGeocode = async ({ latitude, longitude }) => {
  try {
    const {
      data: { results },
    } = await api.maps.reverseGeocode({ latitude, longitude });
    const {
      formatted_address: formattedAddress,
      address_components: addressComponents,
      geometry: {
        location: { lat, lng },
      },
      place_id: placeId,
    } = results[0];

    const { area, locality, state, pincode, country } =
      extractAddressComponents(addressComponents);

    const locationDetails = {
      area,
      locality,
      state,
      pincode,
      country,
      latitude,
      longitude,
      googleMapsLocationData: {
        formattedAddress,
        addressComponents,
        geometry: { location: { lat, lng } },
        placeId,
      },
    };

    return locationDetails;
  } catch (error) {
    handleError(error);
    return null;
  }
};

export default getLocationDetailsByReverseGeocode;
