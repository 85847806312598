import { ArrowForward } from "@mui/icons-material";
import {
  Button,
  capitalize,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import ProgressBar from "@/components/ProgressBar";
import Timeline from "@/components/Timeline";
import Title from "@/components/Title";

import AddressCard from ".//AddressCard";
import BankDetailsCard from ".//BankDetailsCard";
import BasicDetailsCard from ".//BasicDetailsCard";
import ConfigurationCard from ".//ConfigurationCard";
import LegalDetailsCard from ".//LegalDetailsCard";
import SubmitKitchenRegistrationButton from ".//SubmitKitchenRegistrationButton";

const kitchenRegistrationStatusToMuiColor = (status) => {
  switch (status) {
    case "created":
      return "info";
    case "applied":
      return "warning";
    case "approved":
      return "success";
    case "declined":
      return "error";
    default:
      return "default";
  }
};

const ExistingKitchenRegistration = ({
  kitchenRegistration,
  fetchKitchenRegistration,
}) => {
  const navigate = useNavigate();
  const isEditable = ["created", "declined"].includes(
    kitchenRegistration.status,
  );

  const steps = [
    {
      field: "name",
      label: "Basic Details",
      path: "/kitchen-registration/basic-details",
      isComplete: false,
    },
    {
      field: "address",
      label: "Address",
      path: "/kitchen-registration/address",
      isComplete: false,
    },
    {
      field: "configuration",
      label: "Configuration",
      path: "/kitchen-registration/configuration",
      isComplete: false,
    },
    {
      field: "bankDetails",
      label: "Bank Details",
      path: "/kitchen-registration/bank-details",
      isComplete: false,
    },
    {
      field: "legalDetails",
      label: "Legal Details",
      path: "/kitchen-registration/legal-details",
      isComplete: false,
    },
  ];

  const totaStepsCount = steps.length;
  let completedStepsCount = 0;

  steps.forEach((step) => {
    if (kitchenRegistration[step.field]) {
      step.isComplete = true;
      completedStepsCount++;
    }
  });

  const incompleteStep = steps.find((step) => !step.isComplete);

  return (
    <>
      <Title
        button={
          <Chip
            size="small"
            label={capitalize(kitchenRegistration.status)}
            color={kitchenRegistrationStatusToMuiColor(
              kitchenRegistration.status,
            )}
          />
        }
      >
        kitchen Registration
      </Title>
      <Card>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "30%" }}>
              <ProgressBar
                actual={completedStepsCount}
                total={totaStepsCount}
                label={"Completed"}
                color={
                  completedStepsCount === totaStepsCount ? "green" : "orange"
                }
              />
            </div>
            <div style={{ width: "65%" }}>
              <Typography
                variant="subtitle1"
                fontWeight={"bold"}
                style={{ marginBottom: "10px" }}
              >
                {kitchenRegistration.name}
              </Typography>
              {incompleteStep && (
                <Button
                  variant="contained"
                  color="warning"
                  fullWidth
                  size="small"
                  endIcon={<ArrowForward />}
                  onClick={() => navigate(incompleteStep.path)}
                >
                  {incompleteStep.label}
                </Button>
              )}
              {isEditable && completedStepsCount === totaStepsCount && (
                <SubmitKitchenRegistrationButton
                  fetchKitchenRegistration={fetchKitchenRegistration}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      <Timeline timeline={kitchenRegistration.timeline} />
      <BasicDetailsCard
        isEditable={isEditable}
        kitchenName={kitchenRegistration.name}
        kitchenTagline={kitchenRegistration.tagline}
        ownerName={kitchenRegistration.ownerDetails.name}
        ownerNumber={kitchenRegistration.ownerDetails.mobileDetails.mobile}
      />
      <AddressCard
        isEditable={isEditable}
        address={kitchenRegistration.address}
      />
      <ConfigurationCard
        isEditable={isEditable}
        configuration={kitchenRegistration.configuration}
      />
      <BankDetailsCard
        isEditable={isEditable}
        bankDetails={kitchenRegistration.bankDetails}
      />
      <LegalDetailsCard
        isEditable={isEditable}
        legalDetails={kitchenRegistration.legalDetails}
      />
    </>
  );
};

export default ExistingKitchenRegistration;
