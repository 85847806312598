import { useState } from "react";
import { Button, Container } from "@mui/material";

import BottomDrawer from "@/components/BottomDrawer";

import MenuScheduleForm from "../MenuScheduleForm";

const CreateMenu = () => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  return (
    <>
      <Button onClick={openDrawer} size="small" fullWidth variant="contained">
        Create and Schedule New Menu
      </Button>
      <BottomDrawer isOpen={open} setIsOpen={setOpen} title={"Schedule Menu"}>
        <Container>
          <MenuScheduleForm />
        </Container>
      </BottomDrawer>
    </>
  );
};

export default CreateMenu;
