import AddressEditPage from "./pages/AddressEditPage";
import AddressPage from "./pages/AddressPage";
import BankDetailsPage from "./pages/BankDetailsPage";
import BasicDetailsPage from "./pages/BasicDetailsPage";
import ConfigurationPage from "./pages/ConfigurationPage";
import KitchenRegistrationPage from "./pages/KitchenRegistrationPage";
import LegalDetailsPage from "./pages/LegalDetailsPage";

const kitchenRegistrationRoutes = [
  {
    path: "kitchen-registration",
    component: <KitchenRegistrationPage />,
    isProtected: true,
  },
  {
    path: "kitchen-registration/basic-details",
    component: <BasicDetailsPage />,
    isProtected: true,
  },
  {
    path: "kitchen-registration/address",
    component: <AddressPage />,
    isProtected: true,
  },
  {
    path: "kitchen-registration/address/edit",
    component: <AddressEditPage />,
    isProtected: true,
  },
  {
    path: "kitchen-registration/configuration",
    component: <ConfigurationPage />,
    isProtected: true,
  },
  {
    path: "kitchen-registration/bank-details",
    component: <BankDetailsPage />,
    isProtected: true,
  },
  {
    path: "kitchen-registration/legal-details",
    component: <LegalDetailsPage />,
    isProtected: true,
  },
];

export default kitchenRegistrationRoutes;
