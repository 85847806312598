import { TextField as TextFieldMui } from "@mui/material";

const TextField = ({
  label,
  name,
  formik,
  uppercase,
  number,
  maxLength,
  style,
  type,
  multiline,
  rows,
}) => {
  let handleChange = formik.handleChange;

  if (uppercase) {
    handleChange = (e) => {
      formik.handleChange({
        target: {
          name: e.target.name,
          value: e.target.value.toUpperCase(),
        },
      });
    };
  }

  if (number) {
    handleChange = (e) => {
      const value = e.target.value;
      if (/^\d*$/.test(value)) {
        formik.handleChange({
          target: {
            name: e.target.name,
            value,
          },
        });
      }
    };
  }

  return (
    <TextFieldMui
      type={type || "text"}
      size="small"
      fullWidth
      label={label}
      name={name}
      value={formik.values[name]}
      onChange={handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      margin="dense"
      inputProps={{ maxLength }}
      style={style}
      multiline={multiline}
      rows={rows || 1}
    />
  );
};

export default TextField;
