import { Card, CardContent } from "@mui/material";

import LabelValueText from "@/components/LabelValueText";
import { verticalComponentMargin } from "@/utils/styleConstants";

import CardHeader from "./CardHeader";

const BasicDetailsCard = ({
  isEditable,
  kitchenName,
  kitchenTagline,
  ownerName,
  ownerNumber,
}) => {
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <CardHeader
          title={"Basic Details"}
          editPath={isEditable && "basic-details"}
        />
        <LabelValueText label="Kitchen Name" value={kitchenName} />
        {kitchenTagline && (
          <LabelValueText label="Kitchen Tagline" value={kitchenTagline} />
        )}
        <LabelValueText label="Owner Name" value={ownerName} />
        <LabelValueText label="Owner Phone Number" value={ownerNumber} />
      </CardContent>
    </Card>
  );
};

export default BasicDetailsCard;
