import { Button, Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import FormComponents from "@/components/FormComponents";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { useData } from "@/contexts/DataContext";
import { useKitchen } from "@/contexts/KitchenContext";
import handleError from "@/utils/handleError";

import { initialValues, validationSchema } from "../utils/menuFormData";

const AddMenuPage = () => {
  const navigate = useNavigate();
  const data = useData();
  const { kitchen } = useKitchen();

  const formBuilder = [
    {
      type: "select",
      label: "Cuisine",
      state: "cuisine",
      options: kitchen.configuration.cuisines.map((cuisine) => {
        return {
          label: data.cuisineLabels[cuisine],
          value: cuisine,
        };
      }),
      value: "",
    },
    {
      type: "select",
      label: "Meal Type",
      state: "mealType",
      options: kitchen.configuration.mealTypes.map((mealType) => {
        return {
          label: data.mealTypeLabels[mealType],
          value: mealType,
        };
      }),
      value: "",
    },
    {
      type: "select",
      label: "Meal Time",
      state: "mealTime",
      options: kitchen.configuration.mealTimes.map((mealTime) => {
        return {
          label: data.mealTimeLabels[mealTime],
          value: mealTime,
        };
      }),
      value: "",
    },
    {
      type: "textfield",
      label: "Menu Title",
      state: "title",
      value: "",
    },
    {
      type: "textfield",
      multiline: true,
      rows: 3,
      label: "Menu Description",
      state: "description",
      value: "",
    },
    {
      type: "textfield",
      label: "Price",
      state: "price",
      number: true,
      value: "",
    },
  ];

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await api.menus.createMenu(values);
        toast.success("Menu added successfully");
        navigate(-1);
      } catch (err) {
        handleError(err);
      }
    },
  });

  return (
    <>
      <Header />
      <Container>
        <Title>Add Menu</Title>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents formik={formik} data={formBuilder} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ marginTop: "10px" }}
          >
            Add Menu
          </Button>
        </form>
      </Container>
    </>
  );
};

export default AddMenuPage;
