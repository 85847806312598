import { useEffect, useState } from "react";

import constructdisplayAddress from "../utils/constructDisplayAddress";
import getLocationDetailsByGooglePlace from "../utils/getLocationDetailsByGooglePlace";
import getLocationDetailsByReverseGeocode from "../utils/getLocationDetailsByReverseGeocode.js";

const useAddress = ({ address, submitHandler }) => {
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isGeocoding, setIsGeocoding] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    area: "",
    locality: "",
    state: "",
    pincode: "",
    country: "",
    latitude: 0,
    longitude: 0,
    googleMapsLocationData: {
      formattedAddress: "",
      addressComponents: [],
      geometry: { location: { lat: 0, lng: 0 } },
      placeId: "",
    },
  });
  const displayAddress = constructdisplayAddress(locationDetails);

  useEffect(() => {
    if (address) {
      setLocationDetails({
        area: address.area || "",
        locality: address.locality || "",
        state: address.state || "",
        pincode: address.pincode || "",
        country: address.country || "",
        latitude: address?.location?.coordinates[1],
        longitude: address?.location?.coordinates[0],
        googleMapsLocationData: address.googleMapsLocationData,
      });
    }
  }, [address]);

  const handleSuggestionSelect = async (suggestion) => {
    const locationDetails = await getLocationDetailsByGooglePlace(
      suggestion.placePrediction.placeId,
    );

    if (locationDetails) {
      setLocationDetails(locationDetails);
      setSuggestions([]);
    }
  };

  const reverseGeocodeLocation = async ({ latitude, longitude }) => {
    setIsGeocoding(true);
    const locationDetails = await getLocationDetailsByReverseGeocode({
      latitude,
      longitude,
    });
    setIsGeocoding(false);
    if (locationDetails) {
      setLocationDetails(locationDetails);
      setSuggestions([]);
      setSearchValue("");
    }
  };

  const addressSubmitHandler = (addressDetails) => {
    submitHandler({
      ...addressDetails,
      ...locationDetails,
    });
    setIsOpen(false);
  };

  return {
    searchValue,
    setSearchValue,
    suggestions,
    setSuggestions,
    isGeocoding,
    isOpen,
    setIsOpen,
    locationDetails,
    displayAddress,
    handleSuggestionSelect,
    reverseGeocodeLocation,
    addressSubmitHandler,
  };
};

export default useAddress;
