import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMui,
} from "@mui/material";

const Select = ({ label, name, formik, options = [], style }) => {
  return (
    <FormControl
      fullWidth
      size="small"
      margin="dense"
      style={style}
      error={formik.touched[name] && Boolean(formik.errors[name])}
    >
      <InputLabel>{label}</InputLabel>
      <SelectMui
        label={label}
        name={name}
        value={formik.values[name] || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectMui>
      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText>{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
