import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormComponents from "@/components/FormComponents";
import api from "@/api";
import handleError from "@/utils/handleError";

const validationSchema = Yup.object({
  notes: Yup.string(),
});

const SubmitKitchenRegistrationButton = ({ fetchKitchenRegistration }) => {
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      notes: "",
    },
    validationSchema,
    onSubmit: async ({ notes }) => {
      try {
        await api.kitchenRegistrations.submit({
          notes,
        });
        closeDialog();
        toast.success("Kitchen registration submitted for approval");
        fetchKitchenRegistration();
      } catch (error) {
        handleError(error);
      }
    },
  });

  return (
    <>
      <Button
        size="small"
        onClick={openDialog}
        fullWidth
        variant="contained"
        color="success"
      >
        Submit For Approval
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>
          Do you want to submit this kitchen registration for approval?
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} style={{ marginTop: "5px" }}>
            <FormComponents
              formik={formik}
              data={[
                {
                  type: "input",
                  label: "Notes (Optional)",
                  state: "notes",
                  multiline: true,
                  rows: 4,
                },
              ]}
            />
            <DialogActions>
              <Button onClick={closeDialog} color="error">
                Cancel
              </Button>
              <Button type="submit" color="success">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubmitKitchenRegistrationButton;
