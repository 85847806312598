import { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { useParams } from "react-router-dom";

import ColonText from "@/components/ColonText";
import MenuDisplay from "@/components/MenuDisplay";
import api from "@/api";
import { useData } from "@/contexts/DataContext";
import { useKitchen } from "@/contexts/KitchenContext";
import { verticalComponentMargin } from "@/utils/styleConstants";

const MenuScheduleInfo = () => {
  const { kitchen } = useKitchen();
  const { cuisine, mealType, mealTime, day } = useParams();
  const data = useData();
  const [menuSchedule, setMenuSchedule] = useState(null);

  useEffect(() => {
    const fetchMenuSchedule = async () => {
      try {
        const {
          data: { menuSchedule },
        } = await api.menuSchedules.get({
          kitchenId: kitchen._id,
          cuisine,
          mealType,
          mealTime,
          day,
        });

        setMenuSchedule(menuSchedule);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMenuSchedule();
  }, [cuisine, mealType, mealTime, day, kitchen._id]);

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <ColonText first="Cuisine" second={data.cuisineLabels[cuisine]} />
        <ColonText first="Meal Type" second={data.mealTypeLabels[mealType]} />
        <ColonText first="Meal Time" second={data.mealTimeLabels[mealTime]} />
        <ColonText first="Day" second={data.dayLabels[day]} />
        {menuSchedule && (
          <MenuDisplay
            title={menuSchedule.menu.title}
            description={menuSchedule.menu.description}
            price={menuSchedule.menu.price}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default MenuScheduleInfo;
