import {
  Card,
  CardContent,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import TextField from "@/components/FormikComponents/TextField";
import { verticalComponentMargin } from "@/utils/styleConstants";

const GstDetails = ({ formik }) => {
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <Typography variant="subtitle1">GST Details (if applicable)</Typography>
        <Typography variant="caption" color={"text.secondary"}>
          This should be linked to the PAN provided earlier for tax calculations
        </Typography>
        <Typography variant="body2" style={{ marginTop: "10px" }}>
          Are you GST registered?
        </Typography>
        <RadioGroup
          row
          name="isGstRegistered"
          value={formik.values.isGstRegistered}
          onChange={formik.handleChange}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        {formik.touched.isGstRegistered && formik.errors.isGstRegistered && (
          <FormHelperText error>{formik.errors.isGstRegistered}</FormHelperText>
        )}
        {formik.values.isGstRegistered === "yes" && (
          <TextField
            label="GST Number*"
            name="gstNumber"
            formik={formik}
            uppercase
          />
        )}
      </CardContent>
    </Card>
  );
};

export default GstDetails;
