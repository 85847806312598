import { Card, CardContent, Typography } from "@mui/material";

import Map from "@/components/Map";

import CardHeader from "./CardHeader";

const AddressCard = ({ isEditable, address }) => {
  if (!address) return null;

  const { location, displayAddress } = address;
  const [longitude, latitude] = location.coordinates;

  return (
    <Card>
      <CardContent>
        <CardHeader title="Address" editPath={isEditable && "address"} />
        <Typography style={{ marginBottom: "10px" }}>
          {displayAddress}
        </Typography>
        <Map latitude={latitude} longitude={longitude} height="200px" />
      </CardContent>
    </Card>
  );
};

export default AddressCard;
