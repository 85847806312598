import React, { useCallback, useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, FormHelperText, Typography } from "@mui/material";

const MAX_IMAGE_SIZE_MB = 2;

const ImageUpload = ({ label, onImageUpload, imageSrc }) => {
  const [imageState, setImageState] = useState({
    file: null,
    previewUrl: null,
    fileName: "",
    error: "",
  });

  // Cleanup preview URL when component unmounts or when previewUrl changes
  useEffect(() => {
    return () => {
      if (imageState.previewUrl && imageState.previewUrl.startsWith("blob:")) {
        URL.revokeObjectURL(imageState.previewUrl);
      }
    };
  }, [imageState.previewUrl]);

  // Initialize with imageSrc if provided
  useEffect(() => {
    if (imageSrc && !imageState.previewUrl) {
      setImageState((prev) => ({
        ...prev,
        previewUrl: imageSrc,
      }));
    }
  }, [imageSrc]);

  const handleChange = useCallback(
    (event) => {
      const file = event.currentTarget.files[0];

      if (!file) return;

      // Check file size
      if (file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
        setImageState((prev) => ({
          ...prev,
          error: `Image size should be less than ${MAX_IMAGE_SIZE_MB} MB.`,
        }));
        return;
      }

      // Revoke previous blob URL if it exists
      if (imageState.previewUrl && imageState.previewUrl.startsWith("blob:")) {
        URL.revokeObjectURL(imageState.previewUrl);
      }

      const newPreviewUrl = URL.createObjectURL(file);

      setImageState({
        file,
        previewUrl: newPreviewUrl,
        fileName: file.name,
        error: "",
      });

      onImageUpload(file);
    },
    [onImageUpload, imageState.previewUrl],
  );

  const handleRemove = useCallback(() => {
    // Revoke blob URL if it exists
    if (imageState.previewUrl && imageState.previewUrl.startsWith("blob:")) {
      URL.revokeObjectURL(imageState.previewUrl);
    }

    setImageState({
      file: null,
      previewUrl: null,
      fileName: "",
      error: "",
    });

    onImageUpload(null);
  }, [onImageUpload, imageState.previewUrl]);

  return (
    <div>
      <Typography
        variant="subtitle1"
        style={{ marginBottom: "5px" }}
        color="textSecondary"
      >
        {label}
      </Typography>

      {imageState.file ? (
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={handleRemove}
          aria-label="remove image"
        >
          Remove Image <DeleteIcon style={{ marginLeft: "5px" }} />
        </Button>
      ) : (
        <>
          <input
            type="file"
            accept="image/*"
            id="image-upload-input"
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <label htmlFor="image-upload-input">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              component="span"
              aria-label="upload image"
            >
              {imageSrc ? "Change Image" : "Upload Image"}
              <CloudUploadIcon style={{ marginLeft: "5px" }} />
            </Button>
          </label>
        </>
      )}

      {imageState.error && (
        <FormHelperText error style={{ marginTop: "5px" }}>
          {imageState.error}
        </FormHelperText>
      )}

      {imageState.previewUrl && (
        <>
          <div
            style={{
              marginTop: "10px",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={imageState.previewUrl}
              alt={`${label} Preview`}
              style={{ width: "100%", height: "auto" }}
              aria-describedby="selected-image-helper-text"
            />
          </div>
          {imageState.fileName && (
            <FormHelperText
              style={{ color: "green" }}
              id="selected-image-helper-text"
            >
              Image selected: {imageState.fileName}
            </FormHelperText>
          )}
        </>
      )}
    </div>
  );
};

export default ImageUpload;
