import axios from "axios";

let base = "/kitchenRegistrations/";

const initiateKitchenRegistration = async (formData) =>
  (await axios.post(base, formData)).data;

const getMyKitchenRegistration = async () =>
  (await axios.get(base + "myKitchenRegistration")).data;

const verifyMobile = async ({ otp }) =>
  (await axios.patch(base + "verifyMobile", { otp })).data;

const updateNewMobile = async ({ mobile }) =>
  (await axios.patch(base + "newMobile", { mobile })).data;

const verifyNewMobile = async ({ otp }) =>
  (await axios.patch(base + "verifyNewMobile", { otp })).data;

const updateBasicDetails = async (formData) =>
  (await axios.patch(base + "basicDetails", formData)).data;

const updateAddress = async (address) =>
  (await axios.patch(base + "address", address)).data;

const updateConfiguration = async ({
  cuisines,
  mealTypes,
  mealTimes,
  periods,
  servingCapacityPerMealTime,
  deliveryDays,
  deliveryRadius,
}) =>
  (
    await axios.patch(base + "configuration", {
      cuisines,
      mealTypes,
      mealTimes,
      periods,
      servingCapacityPerMealTime,
      deliveryDays,
      deliveryRadius,
    })
  ).data;

const updatePricing = async ({
  basePrice,
  cuisineMultipliers,
  mealTypeMultipliers,
  mealTimeMultipliers,
  periodMultipliers,
}) =>
  (
    await axios.patch(base + "pricing", {
      basePrice,
      cuisineMultipliers,
      mealTypeMultipliers,
      mealTimeMultipliers,
      periodMultipliers,
    })
  ).data;

const updateBankDetails = async ({ accountNumber, ifscCode }) =>
  (
    await axios.patch(base + "bankDetails", {
      accountNumber,
      ifscCode,
    })
  ).data;

const updateLegalDetails = async ({
  panNumber,
  panName,
  isGstRegistered,
  gstNumber,
  fssaiNumber,
}) =>
  (
    await axios.patch(base + "legalDetails", {
      panNumber,
      panName,
      isGstRegistered,
      gstNumber,
      fssaiNumber,
    })
  ).data;

const submit = async ({ notes }) =>
  (
    await axios.patch(base + "submit", {
      notes,
    })
  ).data;

const kitchenRegistrations = {
  initiateKitchenRegistration,
  getMyKitchenRegistration,
  verifyMobile,
  updateNewMobile,
  verifyNewMobile,
  updateBasicDetails,
  updateAddress,
  updateConfiguration,
  updatePricing,
  updateBankDetails,
  updateLegalDetails,
  submit,
};

export default kitchenRegistrations;
