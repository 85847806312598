import AddressDetailsDrawer from "./AddressDetailsDrawer";
import CurrentLocation from "./CurrentLocation";
import useAddress from "./hooks/useAddress";
import LocationDisplay from "./LocationDisplay";
import LocationSearch from "./LocationSearch";
import MapWithAlert from "./MapWithAlert";
import SuggestionList from "./SuggestionList";

const Address = ({ address, submitHandler }) => {
  const {
    searchValue,
    setSearchValue,
    suggestions,
    setSuggestions,
    isGeocoding,
    isOpen,
    setIsOpen,
    locationDetails,
    handleSuggestionSelect,
    reverseGeocodeLocation,
    displayAddress,
    addressSubmitHandler,
  } = useAddress({ address, submitHandler });

  return (
    <>
      <LocationSearch
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setSuggestions={setSuggestions}
      />
      <CurrentLocation onLocationCapture={reverseGeocodeLocation} />
      <SuggestionList
        suggestions={suggestions}
        onSuggestionSelect={handleSuggestionSelect}
      />
      {suggestions.length === 0 && (
        <LocationDisplay
          displayAddress={displayAddress}
          confirmLocationHandler={() => setIsOpen(true)}
          btnName={address ? "Update Address Details" : "Add Address Details"}
        />
      )}
      {suggestions.length === 0 && !isGeocoding && (
        <MapWithAlert
          latitude={locationDetails.latitude}
          longitude={locationDetails.longitude}
          handleDragEnd={reverseGeocodeLocation}
        />
      )}
      <AddressDetailsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={address ? "Update Address" : "Add Address"}
        displayAddress={displayAddress}
        building={address?.building || ""}
        landmark={address?.landmark || ""}
        submitHandler={addressSubmitHandler}
      />
    </>
  );
};

export default Address;
