import Input from "./Input";
import Mobile from "./Mobile";
import MultiSelect from "./MultiSelect";
import Select from "../FormikComponents/Select";
import TextField from "../FormikComponents/TextField";

const FormComponents = ({ data, formik, disabled }) => {
  return (
    <>
      {data.map((item, idx) => {
        switch (item.type) {
          case "input":
            return (
              <Input
                key={idx}
                formik={formik}
                label={item.label}
                state={item.state}
                inputType={item.inputType || "text"}
                disabled={item.disabled}
                multiline={item.multiline}
                rows={item.rows}
                restOfProps={item.restOfProps}
              />
            );
          // case "select":
          //   return (
          //     <Select
          //       key={idx}
          //       formik={formik}
          //       label={item.label}
          //       state={item.state}
          //       options={item.options}
          //       disabled={item.disabled}
          //       restOfProps={item.restOfProps}
          //     />
          //   );
          case "mobile":
            return (
              <Mobile
                key={idx}
                formik={formik}
                label={item.label}
                state={item.state}
                disabled={item.disabled}
              />
            );
          case "multiSelect":
            return (
              <MultiSelect
                key={idx}
                formik={formik}
                label={item.label}
                state={item.state}
                options={item.options}
                disabled={item.disabled}
              />
            );
          case "textfield":
            return (
              <TextField
                key={idx}
                formik={formik}
                label={item.label}
                maxLength={item.maxLength}
                multiline={item.multiline}
                name={item.state}
                number={item.number}
                rows={item.rows}
                style={item.style}
                type={item.type}
                uppercase={item.uppercase}
              />
            );
          case "select":
            return (
              <Select
                formik={formik}
                label={item.label}
                name={item.state}
                style={item.style}
                options={item.options}
              />
            );
          default:
            return <></>;
        }
      })}
    </>
  );
};

export default FormComponents;
