import { Typography } from "@mui/material";

const LabelValueText = ({ label, value }) => {
  return (
    <div style={{ margin: "5px 0px" }}>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body2" color={"text.secondary"}>
        {value}
      </Typography>
    </div>
  );
};

export default LabelValueText;
