import { useState } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";

import CategoryTitle from "@/components/CategoryTitle";
import MenuDisplay from "@/components/MenuDisplay";
import api from "@/api";
import handleError from "@/utils/handleError";
import { verticalComponentMargin } from "@/utils/styleConstants";

const MenuCard = ({ menu, fetchMenus }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleArchive = async () => {
    try {
      await api.menus.archiveMenu({ menuId: menu._id });
      toast.success("Menu archived");
      fetchMenus();
    } catch (err) {
      handleError(err);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Card style={{ margin: verticalComponentMargin }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CategoryTitle
              cuisine={menu.cuisine}
              mealType={menu.mealType}
              mealTime={menu.mealTime}
              marginBottom="0px"
            />
            <Button
              size="small"
              variant="outlined"
              color="warning"
              onClick={openDialog}
              endIcon={<ArchiveIcon />}
            >
              Archive
            </Button>
          </div>
          <MenuDisplay
            title={menu.title}
            description={menu.description}
            price={menu.price}
            hideMenuLabel
          />
        </CardContent>
      </Card>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Confirm archive</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive this menu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="success">
            Cancel
          </Button>
          <Button onClick={handleArchive} color="warning">
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuCard;
